import { atom } from "@geome/recoil"
import { BUPAModalKey } from "../BUPATypes"

export const ModalAtom = atom<BUPAModalKey | null>({
  key: "ModalAtom",
  default: null,
})

export const bupaMobileViewAtom = atom<"map" | "list">({
  key: "bupaMobileViewAtom",
  default: "map",
})
