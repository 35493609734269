import React, { ReactElement } from "react"

type ButtonWithIconProps = {
  icon: ReactElement
  text: string
  onClick: () => void
  count?: number
  countDescription?: string
  className?: string
  autoFocus?: boolean
}

const ButtonWithIcon = ({
  icon,
  text,
  onClick,
  count,
  countDescription,
  autoFocus = false,
  className = "",
}: ButtonWithIconProps): ReactElement => (
  <button autoFocus={autoFocus} className={`button-with-icon ${className}`} onClick={onClick}>
    {count && (
      <span className="button-with-icon__count">
        <span aria-label={countDescription} aria-hidden={!countDescription}>
          {count}
        </span>
      </span>
    )}
    {icon}
    <span>{text}</span>
  </button>
)

export { ButtonWithIcon }
