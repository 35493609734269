import { CountryTermsAndConditions } from "../../BUPATypes"

type PromiseQueueItem = {
  countryCode: string
  promise: Promise<CountryTermsAndConditions>
  fullfill: ((c: CountryTermsAndConditions) => void) | null
  requested: boolean
}

class CountryRequester {
  private baseURL = "/api/v2"
  private currentTimeout: NodeJS.Timeout | null = null
  private promiseQueue: PromiseQueueItem[] = []
  private regionNames: Intl.DisplayNames
  private wait = 500

  private clearTimeout(): void {
    if (this.currentTimeout) clearTimeout(this.currentTimeout)
    this.currentTimeout = null
  }

  async addToQueue(countryCode: string): Promise<CountryTermsAndConditions> {
    this.clearTimeout()
    const queueItem: Partial<PromiseQueueItem> = { countryCode, requested: false }
    queueItem.promise = new Promise<CountryTermsAndConditions>((accept) => {
      queueItem.fullfill = accept
    })
    this.promiseQueue.push(queueItem as PromiseQueueItem)
    this.currentTimeout = setTimeout(async () => this.makeRequest(), this.wait)
    return queueItem.promise
  }

  private attemptToAddCountryName(terms): CountryTermsAndConditions {
    // the internal text is always in english so this is fine
    // - would prefer if it was all localised though
    this.regionNames ||= new Intl.DisplayNames(["en"], { type: "region" })
    // regionNames.of will error if given an "invalid" country code
    // some of our country codes are probably "invalid"
    let countryName
    try {
      countryName = this.regionNames.of(terms.country_code)
    } catch (e) {
      // no-op
    }

    return { ...terms, countryName }
  }

  private async makeRequest(): Promise<void> {
    const currentUrl = new URL(window.location.href)
    const url = new URL(`${this.baseURL}/network`, currentUrl)

    this.promiseQueue
      .filter(({ requested }) => !requested)
      .forEach((p) => {
        url.searchParams.append("country_codes[]", p.countryCode)
        p.requested = true
      })
    const response = await fetch(url.toString())
    const result = (await response.json()) as CountryTermsAndConditions[]

    result.forEach((terms) => {
      const promise = this.promiseQueue.find(
        ({ countryCode }) => countryCode === terms.country_code
      )
      promise?.fullfill?.(this.attemptToAddCountryName(terms))
      this.promiseQueue = this.promiseQueue.filter(
        ({ countryCode }) => countryCode !== terms.country_code
      )
    })
  }
}

const countryRequester = new CountryRequester()
export { countryRequester }
