import { useCallback, useContext, useEffect } from "react"
import { GoogleMapContext } from "@geome/react-components-next/lib/components/map/google/contexts/map"

declare global {
  interface Window {
    __geome_maps: google.maps.Map[] | undefined
  }
}

const DECIMAL_PLACES = 5

const UpdateURLHash = (): null => {
  const map = useContext<google.maps.Map | undefined>(GoogleMapContext)
  // this'll need to be recoil aware when done properly
  // and also (maybe) be bi-directional using the `initialiseState` property
  const updateURLHash = useCallback(() => {
    const centre = map?.getCenter()?.toJSON()
    const zoom = map?.getZoom()
    if (centre && zoom) {
      const { lat, lng } = centre
      window.location.hash = `/@${lat.toFixed(DECIMAL_PLACES)},${lng.toFixed(
        DECIMAL_PLACES
      )},${zoom}z`
    }
  }, [map])

  useEffect(() => {
    const moveListener = map?.addListener("bounds_changed", updateURLHash)
    // to enable map methods in feature tests
    if (["dev", "ci"].includes(process.env.DEPLOY_ENV) && map) {
      if (!window.__geome_maps) window.__geome_maps = []
      window.__geome_maps.push(map)
    }

    return () => moveListener?.remove()
  }, [])

  return null
}

export { UpdateURLHash }
