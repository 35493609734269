import React, { ReactElement, ReactNode } from "react"
import { useModalState } from "../../recoil/ui/hooks"
import { BUPAModalKey } from "../../recoil/BUPATypes"

export const Modal = ({
  children,
  modalKey,
  className = "",
}: {
  children: ReactNode
  modalKey: BUPAModalKey
  className?: string
}): ReactElement | null => {
  const { currentModalKey } = useModalState()
  if (modalKey !== currentModalKey) return null
  return <div className={`modal ${className}`}>{children}</div>
}
