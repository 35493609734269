import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { UIFilterGroups } from "@geome/types"

export const useSortFiltersByTranslation = (filterGroups: UIFilterGroups): UIFilterGroups => {
  const { translate } = useI18n()

  const translatedFilterGroups = filterGroups.map((group) => {
    const translatedGroupName = translate(`filters.${group.groupName}.title`) as string
    const translatedFilters = group.filters.map((f) => ({
      ...f,
      filterText: translate(`${f.groupName}.${f.filterName}`) as string,
    }))

    return { ...group, groupText: translatedGroupName, filters: translatedFilters }
  })

  const sortedFilterGroups = translatedFilterGroups.map((group) => ({
    ...group,
    filters: [...group.filters].sort((a, b) => (a.filterText > b.filterText ? 1 : -1)),
  }))

  return sortedFilterGroups
}
