import { LatLngBounds } from "@geome/types"
import bbox from "@turf/bbox"
import bboxPolygon from "@turf/bbox-polygon"
import transformScale from "@turf/transform-scale"
import { MutableSnapshot } from "@geome/recoil"
import { getAndClearPersistedState } from "../utils/getAndClearPersistedState"
import { targetBoundsAtom } from "@geome/recoil/dist/feature/map/atoms"

const DESKTOP_SCALE = 0.85
const MOBILE_SCALE = 0.4

export const BUPARestoreBoundsState = async ({ set }: MutableSnapshot): Promise<void> => {
  const isMobile = matchMedia("(max-width: 767px)").matches
  const liveBounds = getAndClearPersistedState<LatLngBounds | null>("liveBoundsAtom")
  if (liveBounds) {
    const boundsPolygon = bboxPolygon([
      liveBounds.west,
      liveBounds.south,
      liveBounds.east,
      liveBounds.north,
    ])

    /**
     * We are scaling down bounds by MOBILE -or DESKTOP_SCALE to counteract
     * some undefined behaviour, where google is adding a magic padding
     * when we call fit bounds, i.e. if we do not scale bounds down,
     * the map will be zoomed out by X_SCALE amount, when restored
     */
    const SCALE_BY = isMobile ? MOBILE_SCALE : DESKTOP_SCALE

    const scaled = bbox(transformScale(boundsPolygon, SCALE_BY))
    const [west, south, east, north] = scaled
    const scaledBounds = { west, south, east, north }
    set(targetBoundsAtom, scaledBounds)
  }
}
