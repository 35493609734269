export default {
  search: {
    placeholder: "Search location or facilities",
    your_location: "Your location",
    use_my_location: "Use my location",
    geolocation_status: {
      none: "Use my location",
      error: "Unable to find your location",
      success: "Using your location",
      waiting: "Finding your location",
    },
    aria: {
      intro: "Enter a search term or use the My Location button",
      back_button: "Cancel search",
      clear: "Clear search",
      results: "There are {count} search results",
    },
    completions: {
      facilities: "Facilities",
    },
  },
  mobile_views: {
    list: "List",
    map: "Map",
  },
  terms: {
    country_title: "Important information for {countryName}",
    button: { expand: "More", collapse: "Less" },
    modal: { understand_button: "I understand" },
  },
  locations_list: {
    item: {
      telephone: "<b>Tel:</b> {number}",
      members_telephone: "<b>Members:</b> {number}",
      distance: "{distance, number, ::.0/w unit/kilometer}",
    },
    aria: {
      loading: "Loading locations",
    },
  },
  filters: {
    title: "Filters",
    clear: "Clear",
    applied: `{activeCount,plural, =0 {} other {# applied}}`,
    group_heading: "<b>{group}</b> ({count})",
    done: "Done",
    provider_type: { title: "Provider type" },
    specialties: { title: "Specialties" },
    search: {
      placeholder: "Search filters",
      aria: {
        clear: "Clear filter search",
      },
    },
    aria: {
      applied: `{activeCount,plural, =0 {} other {# filters applied}}`,
    },
  },
  provider_type: {
    clinic: "Clinic",
    consultant: "Consultant",
    dental_clinic: "Dental",
    diagnostic_centre: "Diagnostic Centre",
    hospital: "Hospital",
    laboratory: "Laboratory",
    medical_transport: "Medical Transport",
    other_providers: "Other Providers",
    pharmacy: "Pharmacy",
    physical_therapy_centre: "Physical Therapy Centre",
    optical: "Optical",
    radiology_centre: "Radiology Centre",
  },
  specialties: {
    air_ambulance: "Air Ambulance",
    allergology_immunology: "Allergology & Immunology",
    anaesthesiology: "Anaesthesiology",
    angiosurgeon: "Angiosurgery",
    arthroscopy: "Arthroscopy",
    audiology: "Audiology",
    breast_cancer_services: "Oncology - Breast Cancer Services",
    burns_centre: "Burns Centre",
    cardiology_invasive: "Cardiology - Invasive",
    cardiology_non_invasive: "Cardiology - Non-Invasive",
    cardiology: "Cardiology - General",
    cardiothoracic_surgery: "Cardiothoracic Surgery",
    chiropractic: "Chiropractic",
    dental_clinical_surgery: "Dental Surgery",
    dental_general: "Dental - General",
    dermatology: "Dermatology",
    diabetologist: "Diabetology",
    dietetics_and_nutrition: "Dietetics & Nutrition",
    ear_nose_throat_ent: "Ear, Nose, Throat (ENT)",
    ear_nose_throat: "Ear, Nose, Throat (ENT)",
    ear: "Ear",
    emergency_room: "Emergency Room / A&E",
    endocrinology: "Endocrinology",
    endoscopy: "Endoscopy",
    gamma_knife_surgery: "Gamma Knife Surgery",
    gastro_intestinal_surgery: "Gastrointestinal Surgery",
    gastroenterology: "Gastroenterology",
    general_medicine: "General medicine",
    general_surgery: "General Surgery",
    gynaecology: "Gynaecology",
    haematology: "Haematology",
    haemodialysis: "Haemodialysis / Renal Dialysis",
    health_screening: "Health Screening",
    homeopath: "Homeopathy",
    infant_incubator: "Infant Incubator",
    infectious_disease_medicine: "Infectious Disease Medicine",
    internal_medicine: "General / Internal Medicine",
    laparoscopic_surgery: "Laparoscopic Surgery",
    musculoskeletal: "Musculoskeletal Medicine",
    neonatology: "Neonatology",
    neurology: "Neurology",
    neurosurgery: "Neurosurgery",
    nursing: "Nursing",
    nose: "Nose",
    obstetrics_gynaecology: "Obstetrics",
    occupational_therapy: "Occupational Therapy",
    oncology_chemotherapy: "Oncology - Chemotherapy",
    oncology_general: "Oncology - General",
    oncology_radio_surgery: "Oncology - Radio-surgery",
    oncology_radiotherapy: "Oncology - Radiotherapy",
    ophthalmology: "Ophthalmology",
    optometry: "Optometry",
    oral_facial_surgery: "Oral / Maxillofacial Surgery",
    orthopaedic_surgery: "Orthopaedic Surgery",
    orthopaedics: "Orthopaedics",
    osteopath: "Osteopathy",
    outpatient_clinic: "Outpatient Clinic",
    outpatient_optician: "Outpatient Optician",
    outpatient_pharmacy: "Outpatient Pharmacy",
    paediatrics: "Paediatrics",
    pain_management: "Pain Management",
    pathology_services: "Pathology",
    physical_therapy_centre: "Physical Therapy Centre",
    physician_gp: "Physician / GP",
    physiotherapy: "Physiotherapy",
    plastic_surgery: "Plastic / Reconstructive Surgery",
    podiatry: "Podiatry",
    private_gp_services: "Private GP services",
    psychiatry_psychology: "Psychiatry / Psychology",
    radiology_ct: "Radiology - CT",
    radiology_interventional: "Radiology - Interventional",
    radiology_mri: "Radiology - MRI",
    radiology_pet: "Radiology - PET",
    radiology: "Radiology - General",
    recompression_chamber: "Recompression / Hyperbaric Chamber",
    rehabilitation_services: "Rehabilitation Services",
    renal_dialysis: "Renal dialysis",
    renal_medicine: "Renal Medicine",
    reproductive_medicine: "Reproductive Medicine",
    respiratory_medicine: "Respiratory Medicine",
    rheumatology: "Rheumatology",
    throat_ent: "Throat (ENT)",
    traditional_chinese_medicine: "Traditional Chinese Medicine",
    transplant_surgery: "Transplant Surgery",
    trauma_surgery: "Trauma Surgery",
    ultrasound: "Radiology - Ultrasound",
    urology: "Urology",
    vascular_surgery: "Vascular Surgery",
    x_ray: "Radiology - X-ray",
  },
}
