import React, { ReactElement } from "react"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"

type BUPACompletionFacilitiesHeaderProps = {
  count: number
}

const BUPACompletionFacilitiesHeader = ({
  count,
}: BUPACompletionFacilitiesHeaderProps): ReactElement | null => {
  const { translate } = useI18n()
  if (count === 0) return null
  return (
    <div className="bupa-completion-facilities-header">
      <strong>{translate("search.completions.facilities")}</strong>
    </div>
  )
}

export { BUPACompletionFacilitiesHeader }
