import { AppConfig, Locale } from "@geome/types"
import { langCodeToCountryCode } from "../../utils/langCodeToCountryCode"

type QueryParams = { [key: string]: string | undefined }

export const handleAllEuro = (queryParams: QueryParams): Partial<AppConfig> => {
  if (queryParams.locale?.includes("ALLEURO")) {
    const area = "ALLEURO"
    const lang = queryParams.locale.split("_")[0]
    const locale = queryParams.locale.replace("ALLEURO", langCodeToCountryCode(lang)) as Locale

    return { ...queryParams, area, locale }
  }

  return queryParams
}
