import { MutableSnapshot } from "@geome/recoil"
import { getAndClearPersistedState } from "../utils/getAndClearPersistedState"
import { selectionIntentAtom } from "@geome/recoil/dist/feature/selection/atoms"

import {
  googlePlacesPoolAtom,
  selectedGooglePlaceIdAtom,
} from "@geome/recoil/dist/feature/places/atoms"
import { GooglePlaceLiteral } from "@geome/types"

export const BUPARestoreSearchState = async ({ set }: MutableSnapshot): Promise<void> => {
  const selectedGooglePlace = getAndClearPersistedState<GooglePlaceLiteral | null>(
    "selectedGooglePlaceSelector"
  )
  if (selectedGooglePlace) {
    set(selectionIntentAtom, {
      place_id: selectedGooglePlace.place_id,
      shouldTarget: false,
      type: "google_place",
      description: selectedGooglePlace.name,
    })

    set(googlePlacesPoolAtom, { [selectedGooglePlace.place_id]: selectedGooglePlace })
    set(selectedGooglePlaceIdAtom, selectedGooglePlace.place_id)
  }
}
