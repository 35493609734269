import React, { ReactElement } from "react"
import { useUIFilters } from "@geome/recoil/dist/feature/filters/hooks"
import { FilterHeader } from "./components/filterHeader"
import { useFilterSearch } from "../../hooks/useFilterSearch"
import { useSortFiltersByTranslation } from "../../hooks/useSortFiltersByTranslation"
import { FilterGroups } from "@geome/react-components-next/lib/components/filters/filterGroups"

const Filters = (): ReactElement => {
  const { toggle } = useUIFilters()
  const { filteredGroups: groups } = useFilterSearch()
  const groupsWithSortedFilters = useSortFiltersByTranslation(groups)

  return (
    <div className="filters">
      <FilterHeader />

      <div className="filters__list">
        <FilterGroups groups={groupsWithSortedFilters} onToggleFilter={toggle} />
      </div>
    </div>
  )
}

export { Filters }
