import { useEffect, useMemo, useState } from "react"
import { useRecoilCallback } from "@geome/recoil"
import { CountryTermsAndConditions } from "../../recoil/BUPATypes"
import { CountryTermsAtomFamily } from "../../recoil/countrySpecific/atoms"

const MAX_COUNTRIES_TO_REQUEST = 15

export const useTermsAndConditionsForCountries = ({
  countries,
}: {
  countries: string[]
}): { termsWithText: CountryTermsAndConditions[] } => {
  const [terms, setTerms] = useState<CountryTermsAndConditions[]>([])
  const [renderNudge, setRenderNudge] = useState<number>(0)

  const setTermsFromLoadables = useRecoilCallback(
    ({ snapshot }) =>
      async (countries: string[]) => {
        const termsLoadables = countries.map((countryCode) =>
          snapshot.getLoadable(CountryTermsAtomFamily({ countryCode }))
        )
        setTerms(
          termsLoadables.filter(({ state }) => state === "hasValue").map(({ contents }) => contents)
        )

        const stillLoading = termsLoadables.filter(({ state }) => state === "loading")
        if (stillLoading.length === 0) return
        await Promise.all(stillLoading.map(async (t) => t.promiseMaybe()))
        setRenderNudge((v) => v + 1)
      },
    []
  )

  useEffect(() => {
    setTermsFromLoadables(countries.length <= MAX_COUNTRIES_TO_REQUEST ? countries : [])
  }, [setTermsFromLoadables, renderNudge, countries])

  const termsWithText = useMemo(
    () =>
      terms.filter(
        ({ out_of_network, alternative_network }) => out_of_network || alternative_network
      ),
    [terms]
  )

  return { termsWithText }
}
