import React from "react"
import { createRoot } from "react-dom/client"
import { App } from "./components/app"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/700.css"
import "@total-typescript/ts-reset"
import { registerHighTideServiceWorker } from "@geome/hightide-service-worker"

registerHighTideServiceWorker()

import "../iframe-test.html"

const render = (): void => {
  const container = document.getElementById("app")
  if (!container) throw Error("`.app` element missing")
  const root = createRoot(container)
  root.render(<App />)
}

document.addEventListener("DOMContentLoaded", () => render())
