import { useEffect } from "react"
import { useRecoilValue } from "@geome/recoil"
import { selectedLocationSelector } from "@geome/recoil/dist/feature/locations/selectors"
import { usePersistState } from "../../utils/usePersistState"
import { BUPALocation } from "../../recoil/BUPATypes"

const RouteOnSelection = (): null => {
  const selectedLocation = useRecoilValue(selectedLocationSelector) as BUPALocation | null
  const persist = usePersistState()

  useEffect(() => {
    if (selectedLocation) {
      console.log(
        `Location ${selectedLocation.id} has been selected, and we would route to the BUPA page now`
      )
      persist()
      window.parent.postMessage(
        JSON.stringify({
          provider_ID: selectedLocation.provider_uid,
          distance: selectedLocation.driving_distance ?? selectedLocation.distance,
          search_result_number: selectedLocation.index,
          navigation_url: selectedLocation.url,
        }),
        "*"
      )
    }
  }, [selectedLocation?.id])

  return null
}

export { RouteOnSelection }
