import React, { ReactElement, useEffect } from "react"
import { useMatchMedia } from "../../../hooks/useMatchMedia"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { MapIcon } from "../../icons/map"
import { ListIcon } from "../../icons/list"
import { useCurrentMobileViewState } from "../../../recoil/ui/hooks"
import { BUPALocation } from "../../../recoil/BUPATypes"
import { useLocations } from "@geome/recoil/dist/feature/locations/hooks"
import { UIPadding } from "@geome/types"

export const useMobileView = (
  setUIPadding: (padding: UIPadding) => void
): {
  showMobileViewButton: boolean
  mobileView: "map" | "list"
  toggleMobileView: () => void
  buttonText: string
  buttonIcon: ReactElement
} => {
  const isMobile = useMatchMedia("(max-width: 767px)")
  const { translate } = useI18n()
  const { mobileView, setMobileView, toggleMobileView } = useCurrentMobileViewState()
  const locations = useLocations<BUPALocation>({ endpoint: "nearest_to", resource: "locations" })

  useEffect(() => {
    if (isMobile) {
      setUIPadding({ padding: undefined, resizeMap: false })
    } else {
      setMobileView("list")
    }
  }, [isMobile, setUIPadding])

  const buttonText = translate(`mobile_views.${mobileView === "list" ? "map" : "list"}`) as string
  const buttonIcon = mobileView === "list" ? <MapIcon /> : <ListIcon />

  return {
    showMobileViewButton: isMobile && locations.length > 0,
    mobileView,
    toggleMobileView,
    buttonText,
    buttonIcon,
  }
}
