import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import React, { ReactElement } from "react"

export const LoadingIndicator = ({
  barCount = 3,
  delay = 0.1,
}: {
  barCount?: number
  delay?: number
}): ReactElement => {
  const { translate } = useI18n()

  return (
    <div
      className="loading-indicator"
      aria-busy="true"
      aria-live="polite"
      aria-label={translate("locations_list.aria.loading") as string}
    >
      {new Array(barCount).fill(null).map((_, index) => (
        <div
          className="loading-indicator__bar"
          key={index}
          style={{ animationDelay: `-${delay * (barCount - index)}s` }}
        ></div>
      ))}
    </div>
  )
}
