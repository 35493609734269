import React, { ReactElement } from "react"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { ButtonWithIcon } from "@geome/react-components-next/lib/components/buttonWithIcon"
import { useModalState } from "../../../recoil/ui/hooks"
import { FilterIcon } from "../../icons/filters"
import { useUIFilters } from "@geome/recoil/dist/feature/filters/hooks"

export const FilterModalToggle = (): ReactElement => {
  const { translate } = useI18n()
  const { setCurrentModalKey } = useModalState()
  const { activeCount } = useUIFilters()

  const count = activeCount > 0 ? activeCount : undefined
  const countDescription = count
    ? (translate("filters.aria.applied", { activeCount: count }) as string)
    : undefined

  return (
    <ButtonWithIcon
      className="filter-toggle-button"
      onClick={async () => setCurrentModalKey("filters")}
      text={translate("filters.title") as string}
      icon={<FilterIcon />}
      count={count}
      countDescription={countDescription}
    />
  )
}
