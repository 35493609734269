import React, { ReactElement } from "react"
import { BUPAMarker } from "./bupaMarker"
import { useLocations } from "@geome/recoil/dist/feature/locations/hooks"
import { BUPALocation } from "../../recoil/BUPATypes"

const Locations = (): ReactElement => {
  const locations = useLocations<BUPALocation>({ resource: "locations", endpoint: "within_bounds" })
  return (
    <>
      {locations.map((location) => (
        <BUPAMarker key={location.id} subject={location} />
      ))}
    </>
  )
}

export { Locations }
