import React from "react"
import { Marker } from "@geome/react-components-next/lib/components/map/google/marker"
import { useHighlightLocation } from "@geome/recoil/dist/feature/locations/hooks"
import { BUPALocation } from "../../recoil/BUPATypes"
import { useSelectLocation } from "@geome/recoil/dist/feature/selection/hooks"
import { useBUPAIcon } from "../../hooks/useBUPAIcon"

interface BUPAMarkerProps {
  subject: BUPALocation
}

const BUPAMarker = ({ subject }: BUPAMarkerProps): JSX.Element => {
  const icon = useBUPAIcon(subject)
  const onClick = useSelectLocation(subject, "locations")
  const { highlight, unhighlight } = useHighlightLocation(subject, "locations")
  return (
    <Marker
      lat={subject.lat}
      lng={subject.lng}
      icon={icon}
      onClick={onClick}
      onMouseover={highlight}
      onMouseout={unhighlight}
    />
  )
}

export { BUPAMarker }
