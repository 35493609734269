import React, { ReactElement } from "react"

export const BinIcon = (): ReactElement => (
  <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31 7.99999V5.99999C31.0194 5.73238 30.9809 5.46374 30.8872 5.21231C30.7936 4.96088 30.6469 4.73255 30.4572 4.54283C30.2674 4.3531 30.0391 4.20643 29.7877 4.11276C29.5363 4.0191 29.2676 3.98064 29 3.99999H19C18.7324 3.98064 18.4637 4.0191 18.2123 4.11276C17.9609 4.20643 17.7326 4.3531 17.5428 4.54283C17.3531 4.73255 17.2064 4.96088 17.1128 5.21231C17.0191 5.46374 16.9806 5.73238 17 5.99999V7.99999H8V9.99999H9.5L11.69 44H36.3L38.5 9.99999H40V7.99999H31ZM19 6.99999C19 6.30999 19.31 5.99999 20 5.99999H28C28.7 5.99999 29 6.29999 29 6.99999V7.99999H19V6.99999ZM34.3 42H13.7L11.5 9.99999H36.5L34.3 42ZM18.9 39L17.9 15H16L17 39H18.9ZM23 39H25V15H23V39ZM32 15H30.06L29.06 39H31L32 15Z"
      fill="currentColor"
    />
  </svg>
)
