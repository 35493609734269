import { MutableSnapshot } from "@geome/recoil"
import { getAndClearPersistedState } from "../utils/getAndClearPersistedState"
import { CountryTermsAtomFamily } from "./countrySpecific/atoms"

export const BUPARestoreCountryTerms = async ({ set }: MutableSnapshot): Promise<void> => {
  const countryTerms = getAndClearPersistedState<{ country_code: string }[] | null>("countryTerms")

  if (countryTerms)
    countryTerms.forEach((terms) => {
      set(CountryTermsAtomFamily({ countryCode: terms.country_code }), terms)
    })
}
