import React, { ReactElement } from "react"

export const TickIcon = (): ReactElement => (
  <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46 11.39L18.39 38L4 24.13L5.44 22.75L18.39 35.23L44.56 10L46 11.39Z"
      fill="currentColor"
    />
  </svg>
)
