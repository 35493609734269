import React, { ReactElement } from "react"

interface ChevronIconProps {
  direction?: "up" | "down" | "right" | "left"
}

export const ChevronIcon = ({ direction = "up" }: ChevronIconProps): ReactElement => {
  const rotation = { up: 0, down: 180, right: 90, left: 270 }[direction]

  return (
    <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`rotate(${rotation} 24 24)`}>
        <path
          d="M25 17.81L24.02 16.82L23 17.81L7.01 33.8L5.6 32.38L23.98 14L24 14.02L24.02 14L42.4 32.38L40.98 33.8L25 17.81Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
