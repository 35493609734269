import { UIFilterGroups } from "@geome/types"
import { ReactNode } from "react"

export const searchUIFilters = (
  query: string,
  filterGroups: UIFilterGroups,
  translate: (key: string) => ReactNode
): UIFilterGroups => {
  if (query === "") return filterGroups

  return filterGroups.map((group) => ({
    ...group,
    filters: group.filters.filter((f) => {
      const translatedFilterName = translate(`${f.groupName}.${f.filterName}`) as string
      return queryMatchesFiltername(translatedFilterName, query)
    }),
  }))
}

/**
 * Returns true if any word in the filter name is a substring of
 * any word in the query
 */
const queryMatchesFiltername = (filterName: string, query: string): boolean => {
  const filterWords = filterName.split(" ").map((fn) => fn.toLocaleLowerCase())
  const queryWords = query
    .trim()
    .split(" ")
    .map((fn) => fn.toLocaleLowerCase())

  return filterWords.some((filterWord) =>
    queryWords.some((queryWord) => filterWord.includes(queryWord))
  )
}
