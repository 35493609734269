import React, { ReactElement } from "react"

export const ListIcon = (): ReactElement => (
  <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill="currentColor">
      <path d="M44 4V44H4V4H44ZM46 2H2V46H46V2Z" />
      <path d="M40 10H12V12H40V10Z" />
      <path d="M10 10H8V12H10V10Z" />
      <path d="M36 18H12V20H36V18Z" />
      <path d="M10 18H8V20H10V18Z" />
      <path d="M40 26H12V28H40V26Z" />
      <path d="M10 26H8V28H10V26Z" />
      <path d="M28 34H12V36H28V34Z" />
      <path d="M10 34H8V36H10V34Z" />
    </g>
  </svg>
)
