import React, { ReactElement } from "react"
import { BUPACompletion } from "../bupaCompletion"
import { BUPACompletionFacilitiesHeader } from "../bupaCompletion/facilitiesHeader"
import { FilterModalToggle } from "../filters/components/filterModalToggle"
import { Search } from "@geome/react-components-next/lib/components/search/search"
import useResizeObserver from "use-resize-observer"
import { ButtonWithIcon } from "../buttonWithIcon"

import { useMatchMedia } from "../../hooks/useMatchMedia"
import { useMobileView } from "./hooks/useMobileView"
import { useSetUIPadding } from "@geome/recoil/dist/feature/map/hooks"
import { InfiniteScrollPagedList } from "../infiniteScrollPagedList"
import { CSSCustomPropertyContextProvider } from "@geome/react-next/lib/context/cssCustomProperty"
import { MagnifyingGlassIcon } from "../icons/magnifyingGlass"

const AppSideBar = (): ReactElement => {
  const setUIPadding = useSetUIPadding()
  const isMobile = useMatchMedia("(max-width: 767px)")
  const { showMobileViewButton, mobileView, toggleMobileView, buttonText, buttonIcon } =
    useMobileView(setUIPadding)

  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: (size) => {
      if (isMobile) return
      const padding = { top: 0, left: size.width || 0, right: 0, bottom: 0 }
      const resizeMap = (size.height || 0) > window.innerHeight * 0.8
      setUIPadding({ padding, resizeMap })
    },
  })

  return (
    <div className="app__sidebar" ref={ref}>
      <CSSCustomPropertyContextProvider
        properties={{ "--base-padding": "20px", "--search-input__text-colour": "#00335b" }}
      >
        <Search
          renderSearchIcon={() => <MagnifyingGlassIcon />}
          completionProps={{
            hasGeolocation: true,
          }}
          renderCompletions={{
            headers: {
              locations: (count) => <BUPACompletionFacilitiesHeader count={count} />,
            },
            completions: {
              google: ({ description, matched_substrings }) => (
                <BUPACompletion
                  type="google"
                  text={description}
                  matchedSubstrings={matched_substrings}
                />
              ),
              locations: ({ value, matched_substrings }) => (
                <BUPACompletion
                  type="location"
                  text={value}
                  matchedSubstrings={matched_substrings}
                />
              ),
            },
          }}
        />
      </CSSCustomPropertyContextProvider>
      <nav className="app__sidebar-nav">
        {showMobileViewButton && (
          <ButtonWithIcon
            className="map-list-toggle"
            text={buttonText}
            icon={buttonIcon}
            onClick={toggleMobileView}
          />
        )}
        <FilterModalToggle />
      </nav>
      <InfiniteScrollPagedList showList={mobileView === "list"} />
    </div>
  )
}
export { AppSideBar }
