import { useUIFilters } from "@geome/recoil/dist/feature/filters/hooks"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useRecoilState, useResetRecoilState } from "@geome/recoil"
import { filterSearchAtom } from "../recoil/filters/atoms"
import { searchUIFilters } from "../utils/filter/searchUIFilters"
import { UIFilterGroups } from "@geome/types"

export const useFilterSearch = (): {
  filteredGroups: UIFilterGroups
  searchQuery: string
  setSearchQuery: (text: string) => void
  clearSearchQuery: () => void
} => {
  const [searchQuery, setSearchQuery] = useRecoilState(filterSearchAtom)
  const clearSearchQuery = useResetRecoilState(filterSearchAtom)

  const { groups } = useUIFilters()
  const { translate } = useI18n()

  return {
    filteredGroups: searchUIFilters(searchQuery, groups, translate),
    searchQuery,
    setSearchQuery,
    clearSearchQuery,
  }
}
