import { MutableSnapshot } from "@geome/recoil"
import { getAndClearPersistedState } from "../../utils/getAndClearPersistedState"
import { FilterActiveAtomFamily } from "@geome/recoil/dist/feature/filters/atoms"
import { Filter } from "@geome/types"

export const BUPARestoreFilterState = async ({ set }: MutableSnapshot): Promise<void> => {
  const filters = getAndClearPersistedState<Filter[] | undefined>("Filters")
  if (filters)
    filters.forEach((f) =>
      set(FilterActiveAtomFamily({ groupName: f.groupName, filterName: f.filterName }), f.active)
    )
}
