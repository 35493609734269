import React, { Suspense } from "react"
import { ConfigContextProvider } from "@geome/react-next/lib/context/config"
import { GoogleMapsLoaderUsingConfig } from "@geome/react-components-next/lib/components/map/google/loader"
import { config } from "../../config"
import { Map } from "../map"
import { Resources } from "../resources"
import { RouteOnSelection } from "../routeOnSelection"
import { I18nContextProviderFromConfig } from "@geome/react-next/lib/context/i18nContext/provider"
import { Filters } from "../filters"
import { Modal } from "../modal/modal"
import { AppSideBar } from "./sidebar"
import { initialiseFilterState } from "@geome/recoil/dist/feature/filters/initialisers"
import { initialiseResourcesState } from "@geome/recoil/dist/feature/resources/initialisers"
import { initialiseUIState } from "../../recoil/ui/initial"
import { BUPARestoreFilterState } from "../../recoil/filters/bupaRestoreFilterState"
import { BUPARestoreBoundsState } from "../../recoil/bupaRestoreBoundsState"
import { BUPARestoreSearchState } from "../../recoil/bupaRestoreSearchState"
import { BUPARestoreUIState } from "../../recoil/ui/bupaRestoreUIState"
import { AppConfig } from "@geome/types"
import { RecoilMultiInitRoot } from "@geome/recoil"
import { GeospatialApiRequesterContextProvider } from "@geome/geospatial-api-requester"
import { BUPARestoreLocationState } from "../../recoil/bupaRestoreLocationsState"
import { BUPARestoreCountryTerms } from "../../recoil/bupaRestoreCountryTerms"
import { CSSCustomPropertyContextProvider } from "@geome/react-next/lib/context/cssCustomProperty"
import { TermsAndConditionsModal } from "../termsAndConditions/modal"
import { handleAllEuro } from "./handleAllEuro"
import { FELogger } from "@geome/fe-log-next"
import { CWRLogger } from "@geome/fe-log-next/dist/logger/loggers/awsCloudWatchRUM"

const logger = new CWRLogger()

const App = (): JSX.Element => (
  <FELogger logger={logger}>
    <ConfigContextProvider value={config} transformQueryParams={handleAllEuro}>
      {(finalConfig: AppConfig) => (
        <RecoilMultiInitRoot
          initialisers={[
            initialiseFilterState(finalConfig),
            initialiseResourcesState(finalConfig),
            initialiseUIState,
            BUPARestoreLocationState,
            BUPARestoreUIState,
            BUPARestoreSearchState,
            BUPARestoreFilterState,
            BUPARestoreBoundsState,
            BUPARestoreCountryTerms,
          ]}
        >
          <GeospatialApiRequesterContextProvider>
            <I18nContextProviderFromConfig logError={onIntlError}>
              <GoogleMapsLoaderUsingConfig>
                <CSSCustomPropertyContextProvider properties={{ "--primary-colour": "#00335b" }}>
                  <div className="app">
                    <Suspense fallback={null}>
                      <TermsAndConditionsModal />
                    </Suspense>
                    <Modal modalKey="filters" className="bupa-modal">
                      <Filters />
                    </Modal>
                    <AppSideBar />
                    <Map></Map>
                    <Resources />
                    <RouteOnSelection />
                  </div>
                </CSSCustomPropertyContextProvider>
              </GoogleMapsLoaderUsingConfig>
            </I18nContextProviderFromConfig>
          </GeospatialApiRequesterContextProvider>
        </RecoilMultiInitRoot>
      )}
    </ConfigContextProvider>
  </FELogger>
)

const onIntlError = (e: Error, data: Record<string, unknown>) => {
  console.error(JSON.stringify({ ...e, ...data }))
  logger.log({ locale: data?.locale, key: data?.key }, "intl_error_missing_key")
}

export { App }
