import React, { ReactElement } from "react"

export const MapIcon = (): ReactElement => (
  <svg width="20" height="20" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="rotate(-45 22 22)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 0C17.6488 0 13.3953 1.29028 9.77745 3.70767C6.15957 6.12506 3.33977 9.56099 1.67464 13.581C0.00951707 17.6009 -0.426156 22.0244 0.422718 26.292C1.27159 30.5596 3.36689 34.4796 6.44365 37.5564C9.5204 40.6331 13.4404 42.7284 17.708 43.5773C21.9756 44.4261 26.3991 43.9905 30.419 42.3253C34.439 40.6602 37.8749 37.8404 40.2923 34.2225C42.7097 30.6047 44 26.3512 44 22C44 16.1652 41.6821 10.5695 37.5563 6.44365C33.4305 2.31785 27.8348 0 22 0ZM23 41.77V33.1H29.38C27.8106 36.3614 25.647 39.3016 23 41.77ZM23 31.15V23H31.9C31.7879 25.8027 31.21 28.5671 30.19 31.18L23 31.15ZM25.88 41.68C28.2419 39.1594 30.1622 36.2588 31.56 33.1H38.71C37.236 35.3056 35.3414 37.1987 33.1347 38.671C30.9279 40.1433 28.4525 41.1658 25.85 41.68H25.88ZM23 10.9V2.23C25.6501 4.69948 27.8141 7.6436 29.38 10.91L23 10.9ZM31.56 10.9C30.1622 7.74118 28.2419 4.84055 25.88 2.32C31.1177 3.37076 35.7247 6.45646 38.69 10.9H31.56ZM23 21V12.85H30.2C31.2123 15.4543 31.7867 18.2082 31.9 21H23ZM42 23C41.8687 25.8527 41.1216 28.6433 39.81 31.18H32.3C33.2302 28.5482 33.7529 25.7897 33.85 23H42ZM42 21H33.85C33.7529 18.2103 33.2302 15.4518 32.3 12.82H39.83C41.1347 15.3583 41.875 18.1488 42 21ZM21 2.23V10.9H14.65C16.2104 7.64107 18.3638 4.70101 21 2.23ZM21 33.1V41.78C18.3499 39.3105 16.1859 36.3664 14.62 33.1H21ZM11.7 31.15H4.16999C2.86946 28.6208 2.12932 25.841 1.99999 23H10.17C10.2637 25.7785 10.7796 28.5266 11.7 31.15ZM1.99999 21C2.13124 18.1473 2.87835 15.3567 4.18999 12.82H11.7C10.7698 15.4518 10.2471 18.2103 10.15 21H1.99999ZM12.46 33.1C13.8578 36.2588 15.778 39.1594 18.14 41.68C12.8949 40.6341 8.27997 37.5479 5.30999 33.1H12.46ZM21 23V31.18H13.8C12.7845 28.5662 12.21 25.802 12.1 23H21ZM5.30999 10.9C6.78173 8.69655 8.6732 6.80469 10.8763 5.3325C13.0795 3.86031 15.5511 2.83664 18.15 2.32C15.788 4.84055 13.8678 7.74118 12.47 10.9H5.30999ZM21 12.85V21H12.1C12.2121 18.1973 12.79 15.4329 13.81 12.82L21 12.85Z"
        fill="currentColor"
      />
    </g>
  </svg>
)
