import { AppConfig, DeployEnv } from "@geome/types"
import intlDataMessages from "./intlData/locales/default"

// I wonder where this can go?
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    // eslint-disable-next-line prefer-type-alias/prefer-type-alias
    interface ProcessEnv {
      GOOGLE_API_KEY: string
      DEPLOY_ENV: DeployEnv
    }
  }
}

const configEnv: DeployEnv = ["prod", "uat", "test"].includes(process.env.DEPLOY_ENV || "")
  ? process.env.DEPLOY_ENV
  : "test"

const config: AppConfig = {
  locale: "en_ALLEURO",
  configEnv: configEnv,
  remote: "api/v2/config",
  baseUrl: "/api/v2",
  intlData: {
    messages: intlDataMessages,
  },
  completions: {},
  resources: {
    locations: {
      enabled: true,
      views: ["within_bounds", "nearest_to", "complete"],
    },
  },
  gApiSettings: {
    key: process.env.GOOGLE_API_KEY,
  },
  map: {
    minSearchLocations: 1,
    settings: {
      zoom: 6,
      center: {
        lat: 55,
        lng: -4,
      },
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
      ],
    },
  },
}

export { config }
