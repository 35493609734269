import React, { useCallback, useMemo } from "react"
import { ReactElement } from "react"
import { countryModalSelector } from "../../recoil/countrySpecific/selectors"
import { useRecoilHeldValue } from "../../hooks/useRecoilHeldValue"
import { useTermsAndConditionsForCountries } from "./useTermsAndConditionsForCountries"
import { useI18n } from "@geome/react-next"
import { useSetRecoilState } from "@geome/recoil"
import { dismissedCountryInfoAtom } from "../../recoil/countrySpecific/atoms"

export const TermsAndConditionsModal = (): ReactElement | null => {
  const countryCode = useRecoilHeldValue(countryModalSelector)
  const countries = useMemo(() => [countryCode].filter(Boolean), [countryCode])
  const setDismissedCountryInfo = useSetRecoilState(dismissedCountryInfoAtom)

  const dismissCountryInfo = useCallback(
    (countryCode: string) => {
      setDismissedCountryInfo((s) => [...s, countryCode])
    },
    [setDismissedCountryInfo]
  )

  const { translate } = useI18n()
  const { termsWithText } = useTermsAndConditionsForCountries({
    countries,
  })
  if (termsWithText.length === 0) return null
  const countryTerms = termsWithText[0]

  if (!countryTerms.alternative_network && !countryTerms.out_of_network) return null

  return (
    <div className="terms-and-conditions-modal__container">
      <div className="terms-and-conditions-modal" aria-modal="true">
        <div className="terms-and-conditions-modal__title">
          {translate("terms.country_title", {
            countryName: countryTerms.countryName || countryTerms.country_code,
          })}
        </div>
        <div className="terms-and-conditions-modal__content">
          {countryTerms.out_of_network_text && (
            <div dangerouslySetInnerHTML={{ __html: countryTerms.out_of_network_text }} />
          )}
          {countryTerms.alternative_network_text && (
            <div dangerouslySetInnerHTML={{ __html: countryTerms.alternative_network_text }} />
          )}
        </div>

        <div className="terms-and-conditions-modal__buttons">
          <button
            className="terms-and-conditions-modal__button"
            onClick={() => dismissCountryInfo(countryTerms.country_code)}
          >
            {translate("terms.modal.understand_button")}
          </button>
        </div>
      </div>
    </div>
  )
}
