import React, { ReactElement } from "react"

export const CrossIcon = (): ReactElement => (
  <svg width="14" height="14" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.45 16L32 30.55L30.55 32L16 17.45L1.46 32L0 30.55L14.55 16L0 1.46L1.46 0L16 14.55L30.55 0L32 1.46L17.45 16Z"
      fill="#00335B"
    />
  </svg>
)
