import { SetterOrUpdater, useRecoilCallback, useRecoilState, useRecoilValue } from "@geome/recoil"
import { bupaMobileViewAtom, ModalAtom } from "./atoms"
import { BUPAModalKey } from "../BUPATypes"

export const useModalState = (): {
  currentModalKey: "filters" | null
  setCurrentModalKey: (modalKey: "filters" | null) => Promise<void>
} => {
  const currentModalKey = useRecoilValue(ModalAtom)

  const setCurrentModalKey = useRecoilCallback(
    ({ set }) =>
      async (modalKey: BUPAModalKey | null) => {
        set(ModalAtom, modalKey)
      }
  )

  return {
    currentModalKey,
    setCurrentModalKey,
  }
}

// BUPA specific
export const useCurrentMobileViewState = (): {
  mobileView: "map" | "list"
  setMobileView: SetterOrUpdater<"map" | "list">
  toggleMobileView: () => void
} => {
  const [mobileView, setMobileView] = useRecoilState(bupaMobileViewAtom)

  const toggleMobileView = useRecoilCallback(({ set, snapshot }) => async () => {
    const currentMobileView = await snapshot.getPromise(bupaMobileViewAtom)
    const nextMobileView = currentMobileView === "list" ? "map" : "list"
    set(bupaMobileViewAtom, nextMobileView)
  })

  return {
    mobileView,
    setMobileView,
    toggleMobileView,
  }
}
