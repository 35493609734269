import { useRecoilCallback } from "@geome/recoil"
import { apiFilterSelector } from "@geome/recoil/dist/feature/filters/selectors"
import { bupaMobileViewAtom } from "../recoil/ui/atoms"
import { liveBoundsAtom, UIPaddingAtom } from "@geome/recoil/dist/feature/map/atoms"
import {
  LocationIdsAtomFamily,
  LocationsPoolAtomFamily,
  ResponseMetadataAtomFamily,
} from "@geome/recoil/dist/feature/locations/atoms"

import { ResourcePaginationStatusAtomFamily } from "@geome/recoil/dist/feature/resources/atoms"
import { onScreenCountriesSelector } from "../recoil/countrySpecific/selectors"
import { CountryTermsAtomFamily } from "../recoil/countrySpecific/atoms"
import { selectedGooglePlaceSelector } from "@geome/recoil/dist/feature/places/selectors"

/**
 * Stores the recoil state which should be restored,
 * when people navigate back from a BUPA page
 */
export const usePersistState = (): (() => Promise<void>) => {
  const persist = useRecoilCallback(({ snapshot }) => async () => {
    const get = snapshot.getPromise

    // UI
    storeInSS("bupaMobileViewAtom", await get(bupaMobileViewAtom))
    storeInSS("UIPaddingAtom", await get(UIPaddingAtom))

    // Bounds
    storeInSS("liveBoundsAtom", await get(liveBoundsAtom))

    // Search
    storeInSS("selectedGooglePlaceSelector", await get(selectedGooglePlaceSelector))

    // Filters
    const filters = await get(apiFilterSelector)
    storeInSS("Filters", filters)

    // Locations
    const nearestToLocationIds = await get(
      LocationIdsAtomFamily({ endpoint: "nearest_to", resource: "locations" })
    )
    storeInSS("nearestToLocationIds", nearestToLocationIds)

    const withinBoundsLocationIds = await get(
      LocationIdsAtomFamily({ endpoint: "within_bounds", resource: "locations" })
    )
    storeInSS("withinBoundsLocationIds", withinBoundsLocationIds)

    const allIds = [...nearestToLocationIds, ...withinBoundsLocationIds]

    const pool = await get(LocationsPoolAtomFamily({ resource: "locations" }))

    const filteredPool = Object.fromEntries(allIds.map((id) => [id, pool[id]]))

    storeInSS("locationPool", filteredPool)

    // Location Metadata

    const nearestToMetadata = await get(
      ResponseMetadataAtomFamily({ endpoint: "nearest_to", resource: "locations" })
    )

    storeInSS("nearestToMetadata", nearestToMetadata)

    // Pagination

    storeInSS(
      "paginationStatus",
      await get(
        ResourcePaginationStatusAtomFamily({ resource: "locations", endpoint: "nearest_to" })
      )
    )

    // Terms and Conditons

    const onScreenCountries = await get(onScreenCountriesSelector)

    storeInSS(
      "countryTerms",
      await Promise.all(
        onScreenCountries.map(async (countryCode) => get(CountryTermsAtomFamily({ countryCode })))
      )
    )
  })

  return persist
}

const ss = window.sessionStorage

export const storeInSS = (key: string, value: any): void => {
  ss.setItem(key, JSON.stringify(value))
}
