import React, { ReactElement } from "react"
import { useBUPAIcon } from "../../hooks/useBUPAIcon"
import { useSelectLocation } from "@geome/recoil/dist/feature/selection/hooks"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { BUPALocation } from "../../recoil/BUPATypes"
import HospitalIcon from "../../../images/icon-hospital.svg"
import { useBEM } from "@geome/react-next/lib/hooks/useBEM"
import { useHighlightLocation } from "@geome/recoil/dist/feature/locations/hooks"
import { useCallOnKeyPress } from "@geome/react-components-next/lib/hooks/callOnKeyPress/useCallOnKeyPress"

type LocationsListItemProps = {
  location: BUPALocation
}

const LocationsListItem = ({ location }: LocationsListItemProps): ReactElement => {
  const icon = useBUPAIcon({ ...location, isHighlighted: false })
  const onClick = useSelectLocation(location, "locations")
  const { highlight, unhighlight } = useHighlightLocation(location, "locations")
  const { translate } = useI18n()
  const onKeyDown = useCallOnKeyPress(onClick, ["Enter", " "])

  const {
    id,
    name,
    address_one,
    address_two,
    address_three,
    city,
    country,
    provider_type,
    distance,
    driving_distance,
    telephone,
    additional_telephone,
    isSelected,
    isHighlighted,
  } = location

  const className = useBEM({
    block: "locations-list",
    element: "item",
    modifiers: {
      selected: Boolean(isSelected),
      highlighted: Boolean(isHighlighted),
    },
  })

  return (
    <li
      className={className}
      key={id}
      onClick={onClick}
      onMouseEnter={highlight}
      onMouseLeave={unhighlight}
      onFocus={highlight}
      onBlur={unhighlight}
      tabIndex={0}
      onKeyDown={onKeyDown}
    >
      <img
        className="locations-list__item-icon"
        src={icon.url}
        width={(icon.scaledSize?.width || 28) / 1.5}
        aria-hidden
      />
      <div className="locations-list__item-info">
        <div className="locations-list__item-info-name">{name}</div>
        <div className="locations-list__item-info-address">
          {[address_one, address_two, address_three, city, country].filter(Boolean).join(", ")}
        </div>
        <div className="locations-list__item-info-telephone">
          {telephone && (
            <div>{translate("locations_list.item.telephone", { number: telephone })}</div>
          )}
          {additional_telephone && (
            <div>
              {translate("locations_list.item.members_telephone", { number: additional_telephone })}
            </div>
          )}
        </div>

        <div className="locations-list__item-info-type">
          <img src={HospitalIcon} width="19" />
          {translate(`provider_type.${provider_type}`)}
        </div>
        <div className="locations-list__item-info-distance">
          {translate("locations_list.item.distance", { distance: driving_distance ?? distance })}
        </div>
      </div>
    </li>
  )
}

export { LocationsListItem }
