import { MutableSnapshot } from "@geome/recoil"
import { UIPaddingAtom } from "@geome/recoil/dist/feature/map/atoms"
import { getAndClearPersistedState } from "../../utils/getAndClearPersistedState"
import { bupaMobileViewAtom } from "./atoms"

export const BUPARestoreUIState = async ({ set }: MutableSnapshot): Promise<void> => {
  const bupaMobileView = getAndClearPersistedState("bupaMobileViewAtom")
  if (bupaMobileView) {
    set(bupaMobileViewAtom, bupaMobileView)
  }

  const uiPadding = getAndClearPersistedState("UIPaddingAtom")
  if (uiPadding) {
    set(UIPaddingAtom, uiPadding)
  }
}
