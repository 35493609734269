import { Completion } from "@geome/types"
import React, { ReactElement } from "react"
import HospitalIcon from "../../../images/icon-hospital.svg"
import LocationIcon from "../../../images/icon-location.png"

const wrapSubstrings = (
  text: string,
  substrings: Completion["matched_substrings"]
): ReactElement => {
  if (!substrings || substrings.length === 0) return <>{text}</>
  const [subString, ...subStringsCopy] = [...substrings]
  const startOfText = text.slice(0, subString.offset)
  const match = text.slice(subString.offset, subString.offset + subString.length)
  const restOfText = text.slice(subString.offset + subString.length)
  return (
    <>
      <>{startOfText}</>
      <strong>{match}</strong>
      {wrapSubstrings(restOfText, subStringsCopy)}
    </>
  )
}

type BUPACompletionProps = {
  type: "google" | "location"
  matchedSubstrings?: Completion["matched_substrings"]
  text: string
}

const BUPACompletion = ({ type, text, matchedSubstrings }: BUPACompletionProps): ReactElement => (
  <div className="bupa-completion">
    <img
      className="bupa-completion__icon"
      src={type === "google" ? LocationIcon : HospitalIcon}
      width="21"
    ></img>
    <span className="bupa-completion__text">{wrapSubstrings(text, matchedSubstrings)}</span>
  </div>
)

export { BUPACompletion }
