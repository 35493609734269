import { atom, atomFamily } from "@geome/recoil"
import { CountryTermsAndConditions } from "../BUPATypes"
import { countryRequester } from "./utils/countryRequester"
import { persistInSessionStorageEffect } from "@geome/recoil/dist/effects/persistInLocalStorage"

export const CountryTermsAtomFamily = atomFamily<CountryTermsAndConditions, { countryCode }>({
  key: "CountryTermsAtomFamily",
  default: async ({ countryCode }) => countryRequester.addToQueue(countryCode),
})

export const dismissedCountryInfoAtom = atom<string[]>({
  key: "dismissedCountryInfoAtom",
  default: [],
  effects: [persistInSessionStorageEffect("DismissedCountryInfoAtom")],
})
