import {
  PaintFunc,
  useCanvasIcon,
} from "@geome/react-components-next/lib/hooks/marker/useCanvasIcon"
import { BUPALocation } from "../recoil/BUPATypes"

const EXTRA_WHITESPACE = 2
const MAX_TEXT_WIDTH = 20

const useBUPAIcon = (subject: BUPALocation): google.maps.Icon => {
  const paintFunc: PaintFunc<BUPALocation> = ({ context, subject }) => {
    const scalingFactor = subject.isHighlighted ? 1.3 : 1

    const WIDTH = Math.round((28 + EXTRA_WHITESPACE) * scalingFactor)
    const HEIGHT = Math.round((40 + EXTRA_WHITESPACE) * scalingFactor)
    context.canvas.width = WIDTH * 2
    context.canvas.height = HEIGHT * 2
    context.scale(2 * scalingFactor, 2 * scalingFactor)

    const markerPath = new Path2D(
      "M14 0C10.287 0 6.72601 1.475 4.1005 4.1005C1.475 6.72601 0 10.287 0 14C0 24.5 14 40 14 40C14 40 28 24.5 28 14C28 10.287 26.525 6.72601 23.8995 4.1005C21.274 1.475 17.713 0 14 0Z"
    )
    context.save()
    context.translate(EXTRA_WHITESPACE / 2, EXTRA_WHITESPACE / 2)
    context.fillStyle = "#00335B"
    context.fill(markerPath)
    context.strokeStyle = "white"
    context.stroke(markerPath)
    context.restore()

    if (subject.index) {
      context.fillStyle = "white"
      context.textAlign = "center"
      context.textBaseline = "middle"

      let fontSize = 16

      do {
        fontSize -= 0.5
        context.font = `${fontSize}px Montserrat,sans-serif`
      } while (context.measureText(`${subject.index}`).width > MAX_TEXT_WIDTH)

      context.fillText(
        `${subject.index}`,
        WIDTH / (2 * scalingFactor),
        HEIGHT / (2.5 * scalingFactor)
      )
    }

    return { scaledSize: { width: WIDTH, height: HEIGHT } }
  }

  const icon = useCanvasIcon<BUPALocation>({
    subject,
    paintFunc,
    cacheKeyFunc: ({ subject }) => `${subject.index}-${subject.isHighlighted}`,
  })
  return icon
}

export { useBUPAIcon }
