import { MutableSnapshot } from "@geome/recoil"
import { getAndClearPersistedState } from "../utils/getAndClearPersistedState"
import {
  LocationIdsAtomFamily,
  LocationsPoolAtomFamily,
  ResponseMetadataAtomFamily,
} from "@geome/recoil/dist/feature/locations/atoms"
import { ResourcePaginationStatusAtomFamily } from "@geome/recoil/dist/feature/resources/atoms"

export const BUPARestoreLocationState = async ({ set }: MutableSnapshot): Promise<void> => {
  const withinBoundsLocationIds = getAndClearPersistedState("withinBoundsLocationIds")
  if (withinBoundsLocationIds)
    set(
      LocationIdsAtomFamily({ endpoint: "within_bounds", resource: "locations" }),
      withinBoundsLocationIds
    )

  const nearestToLocationIds = getAndClearPersistedState("nearestToLocationIds")
  if (nearestToLocationIds)
    set(
      LocationIdsAtomFamily({ endpoint: "nearest_to", resource: "locations" }),
      nearestToLocationIds
    )

  const pool = getAndClearPersistedState("locationPool")
  if (pool) set(LocationsPoolAtomFamily({ resource: "locations" }), pool)

  const metadata = getAndClearPersistedState("nearestToMetadata")
  if (metadata)
    set(ResponseMetadataAtomFamily({ resource: "locations", endpoint: "nearest_to" }), metadata)

  const paginationStatus = getAndClearPersistedState("paginationStatus")
  if (paginationStatus)
    set(
      ResourcePaginationStatusAtomFamily({ resource: "locations", endpoint: "nearest_to" }),
      paginationStatus
    )
}
