import React, { ReactElement } from "react"
import { GoogleMap } from "@geome/react-components-next/lib/components/map/google"
import { Locations } from "./locations"
import { SearchPlace } from "@geome/react-components-next/lib/components/map/google/searchPlace"
import { UpdateURLHash } from "./updateUrlHash"
import Clusters from "./clusters"

export const Map = (): ReactElement => (
  <GoogleMap>
    <Locations />
    <SearchPlace />
    <Clusters />
    <UpdateURLHash />
  </GoogleMap>
)
