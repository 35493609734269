import { RecoilValue, useRecoilValueLoadable } from "@geome/recoil"
import { useState, useEffect } from "react"

export const useRecoilHeldValue = <T>(atomOrSelector: RecoilValue<T>): T => {
  const loadable = useRecoilValueLoadable(atomOrSelector)
  const [smoothedValue, setSmoothedValue] = useState<T>(() => {
    if (loadable.state !== "hasValue") throw loadable.toPromise()
    return loadable.getValue()
  })

  useEffect(() => {
    const inner = async (): Promise<void> => {
      const value = await loadable.toPromise()
      setSmoothedValue(value)
    }
    inner()
  }, [loadable])
  return smoothedValue
}
