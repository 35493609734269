import React, { ReactElement, useState } from "react"
import { onScreenCountriesSelector } from "../../recoil/countrySpecific/selectors"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useBEM } from "@geome/react-next/lib/hooks/useBEM"
import { ChevronIcon } from "../icons/chevron"
import { useTermsAndConditionsForCountries } from "./useTermsAndConditionsForCountries"
import { useCallOnKeyPress } from "@geome/react-components-next/lib/hooks/callOnKeyPress/useCallOnKeyPress"
import { useRecoilHeldValue } from "../../hooks/useRecoilHeldValue"

export const TermsAndConditions = (): ReactElement | null => {
  const [listFormatter] = useState(
    //@ts-ignore (ListFormat isn't typed yet)
    () => new Intl.ListFormat("en", { style: "short", type: "conjunction" })
  )
  const countries = useRecoilHeldValue(onScreenCountriesSelector)
  const { termsWithText } = useTermsAndConditionsForCountries({ countries })
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const className = useBEM({
    block: "terms-and-conditions",
    modifiers: { expanded: isExpanded },
  })
  const { translate } = useI18n()

  const onToggle = (event: any): void => {
    event.preventDefault()
    setIsExpanded(!isExpanded)
  }

  const onKeyPress = useCallOnKeyPress(() => setIsExpanded((iE) => !iE), ["Enter", " "])

  if (termsWithText.length === 0) return null
  return (
    <details open={isExpanded} tabIndex={-1} className={className} id="terms-and-conditons">
      <summary
        onClick={onToggle}
        tabIndex={0}
        onKeyPress={onKeyPress}
        className="terms-and-conditions__regions-title"
      >
        <span>
          {translate("terms.country_title", {
            countryName: listFormatter.format(
              termsWithText.map((t) => t.countryName || t.country_code)
            ),
          })}
        </span>
        <ChevronIcon direction={isExpanded ? "up" : "down"} />
      </summary>

      {termsWithText.map((t) => (
        <div key={t.country_code} className="terms-and-conditions__region-info">
          {termsWithText.length > 1 && (
            <h2 className="terms-and-conditions__region-info-title">
              {t.countryName || t.country_code}
            </h2>
          )}
          <div className="terms-and-conditions__region-info-content">
            {t.out_of_network && (
              <div dangerouslySetInnerHTML={{ __html: t.out_of_network_text }} />
            )}
            {t.alternative_network && (
              <div dangerouslySetInnerHTML={{ __html: t.alternative_network_text }} />
            )}
          </div>
        </div>
      ))}
      <button
        className="terms-and-conditions__collapse-button"
        onClick={onToggle}
        aria-expanded={isExpanded}
        aria-controls="terms-and-conditions"
      >
        {translate("terms.button.collapse")}
        <ChevronIcon direction="up" />
      </button>
    </details>
  )
}
