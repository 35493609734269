import React, { ReactElement } from "react"

export const MagnifyingGlassIcon = (): ReactElement => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 43 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.77 25.16C29.8561 25.219 29.9364 25.286 30.01 25.36L41.58 36.93C41.7489 37.1157 41.8796 37.333 41.9645 37.5692C42.0494 37.8055 42.0868 38.0562 42.0748 38.307C42.0627 38.5577 42.0013 38.8037 41.8941 39.0307C41.7869 39.2577 41.636 39.4614 41.45 39.63L38.62 42.46C38.278 42.8332 37.8024 43.0563 37.2967 43.0807C36.7911 43.105 36.2963 42.9287 35.92 42.59L24.36 31C24.286 30.9264 24.219 30.8461 24.16 30.76C20.807 32.7465 16.8443 33.4409 13.0157 32.7129C9.18704 31.9849 5.75575 29.8845 3.36589 26.806C0.976036 23.7275 -0.208051 19.8826 0.0358957 15.993C0.279842 12.1034 1.93505 8.43658 4.69081 5.68082C7.44658 2.92505 11.1134 1.26985 15.003 1.0259C18.8926 0.781955 22.7375 1.96604 25.816 4.3559C28.8945 6.74575 30.9949 10.177 31.7229 14.0057C32.4509 17.8343 31.7565 21.797 29.77 25.15V25.16ZM25.8 27L26 26.8C28.5903 24.1569 30.0286 20.5956 30.0006 16.8949C29.9726 13.1943 28.4804 9.65524 25.8504 7.05164C23.2203 4.44804 19.6664 2.99171 15.9656 3.00105C12.2649 3.0104 8.71836 4.48467 6.10151 7.10151C3.48466 9.71836 2.01039 13.2649 2.00105 16.9657C1.9917 20.6664 3.44804 24.2203 6.05163 26.8504C8.65523 29.4804 12.1943 30.9726 15.8949 31.0006C19.5956 31.0286 23.1568 29.5903 25.8 27ZM25.8 29.64L37.21 41L40 38.21L28.6 26.81C27.7796 27.8624 26.8324 28.8096 25.78 29.63L25.8 29.64Z"
      fill="#00335B"
    />
  </svg>
)
