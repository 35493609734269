export const langCodeToCountryCode = (langCode: string): Uppercase<string> => {
  switch (langCode) {
    case "da":
      return "DK"
    case "el":
      return "GR"
    case "nb":
      return "NO"
    case "sv":
      return "SE"
    default:
      return langCode.toUpperCase() as Uppercase<string>
  }
}
