import { useRecoilValue } from "@geome/recoil"
import { useAPIFilters } from "@geome/recoil/dist/feature/filters/hooks"
import { useSelectedLocation } from "@geome/recoil/dist/feature/locations/hooks"
import { selectedGooglePlaceSelector } from "@geome/recoil/dist/feature/places/selectors"
import { useState, useRef, useEffect, useCallback } from "react"
import { useLocations } from "@geome/recoil/dist/feature/locations/hooks"

export const useRequestNextPage = ({
  nextPage,
  isScrolledToBottom,
  moveToNextPage,
}: {
  nextPage: number | null
  isScrolledToBottom: boolean
  moveToNextPage: () => void
}): { isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState(false)
  const previousNextPageRef = useRef<number | null>(nextPage)
  const locations = useLocations({ endpoint: "nearest_to", resource: "locations" })

  useEffect(() => {
    if (isScrolledToBottom && nextPage && !isLoading && locations.length > 0) {
      moveToNextPage()
      setIsLoading(true)
    }
  }, [isLoading, isScrolledToBottom, moveToNextPage, nextPage, locations])

  useEffect(() => {
    const previousNextPage = previousNextPageRef.current
    if (previousNextPage === null || (nextPage && nextPage > previousNextPage)) {
      setIsLoading(false)
    }
    previousNextPageRef.current = nextPage
  }, [previousNextPageRef, nextPage])

  return { isLoading }
}

export const useManualWriteOnlySessionStorage = (
  key: string
): [(newValue: string | null) => void, () => void] => {
  const [value, setValue] = useState<string | null>(null)

  const writeToStorage = useCallback(() => {
    if (value) {
      sessionStorage.setItem(key, value)
    } else {
      sessionStorage.removeItem(key)
    }
  }, [key, value])

  return [setValue, writeToStorage]
}

export const useReadAndClearSessionStorage = (key: string): string | null => {
  const [value] = useState(() => {
    const v = sessionStorage.getItem(key)
    sessionStorage.removeItem(key)
    return v
  })
  return value
}

export const useRestoreScroll = (): {
  shouldRestore: boolean
  restoredScrollPosition: number | undefined
  setShouldRestore: (boolean) => void
  setCurrentScrollPosition: (string) => void
} => {
  const storedScrollPosition = useReadAndClearSessionStorage("list-position")
  const [setCurrentScrollPosition, writeScrollPosition] =
    useManualWriteOnlySessionStorage("list-position")
  const [shouldRestore, setShouldRestore] = useState(storedScrollPosition !== null)

  const selectedLocation = useSelectedLocation()

  useEffect(() => {
    if (selectedLocation) writeScrollPosition()
  }, [selectedLocation, writeScrollPosition])

  const restoredScrollPosition = shouldRestore ? parseFloat(storedScrollPosition || "0") : undefined

  return {
    shouldRestore,
    restoredScrollPosition,
    setShouldRestore,
    setCurrentScrollPosition,
  }
}

export const useControlPagination = ({
  setShouldRestore,
  resetScroll,
  stopPaginating,
  startPaginating,
}: {
  setShouldRestore: (boolean) => void
  resetScroll: () => void
  stopPaginating: () => void
  startPaginating: (number) => void
}): void => {
  const selectedGooglePlace = useRecoilValue(selectedGooglePlaceSelector)
  const apiFilters = useAPIFilters()
  const lastGooglePlaceRef = useRef<typeof selectedGooglePlace>(selectedGooglePlace)
  const lastApiFilters = useRef(apiFilters)

  useEffect(() => {
    const filtersChange = lastApiFilters.current !== apiFilters
    const googlePlaceChange = lastGooglePlaceRef.current?.place_id !== selectedGooglePlace?.place_id
    if (!(filtersChange || googlePlaceChange)) return

    lastApiFilters.current = apiFilters
    lastGooglePlaceRef.current = selectedGooglePlace

    if (selectedGooglePlace) {
      setShouldRestore(false)
      resetScroll()
      startPaginating(75)
    } else {
      setShouldRestore(false)
      resetScroll()
      stopPaginating()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGooglePlace, apiFilters])
}

export const useSingleFrameToggle = (): [boolean, () => void] => {
  const [value, setValue] = useState(false)

  const toggleValue = useCallback(() => {
    setValue(true)
    requestAnimationFrame(() => setValue(false))
  }, [])

  return [value, toggleValue]
}
