import React, { ReactElement } from "react"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useModalState } from "../../../recoil/ui/hooks"
import { useUIFilters } from "@geome/recoil/dist/feature/filters/hooks"
import SearchIcon from "../../../../images/icon-magnifying-glass.svg"
import { useFilterSearch } from "../../../hooks/useFilterSearch"
import { ButtonWithIcon } from "../../buttonWithIcon"
import { TickIcon } from "../../icons/tick"
import { BinIcon } from "../../icons/bin"
import { CrossIcon } from "../../icons/cross"

export const FilterHeader = (): ReactElement => {
  const { activeCount, onClear } = useUIFilters()
  const { searchQuery, setSearchQuery, clearSearchQuery } = useFilterSearch()
  const { translate } = useI18n()
  const { setCurrentModalKey } = useModalState()

  return (
    <div className="filters__header">
      <div className="filters__header-items">
        <ButtonWithIcon
          text={translate("filters.clear") as string}
          icon={<BinIcon />}
          onClick={onClear}
          autoFocus={true}
        />
        <span className="filters__applied-count">
          {translate("filters.applied", { activeCount })}
        </span>
        <ButtonWithIcon
          text={translate("filters.done") as string}
          icon={<TickIcon />}
          onClick={async () => setCurrentModalKey(null)}
        />
      </div>
      <div className="filters__search">
        <img src={SearchIcon} aria-hidden className="filters__search-search-icon" />
        <input
          className="filters__search-input"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder={translate("filters.search.placeholder") as string}
        />
        {searchQuery.length > 0 && (
          <button
            className="filters__search-clear-button"
            title={translate("filters.search.aria.clear") as string}
            onClick={() => clearSearchQuery()}
          >
            <CrossIcon />
          </button>
        )}
      </div>
    </div>
  )
}
