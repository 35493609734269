import { selector } from "@geome/recoil"
import { actionBoundsAtom } from "@geome/recoil/dist/feature/map/atoms"
import { selectedGooglePlaceSelector } from "@geome/recoil/dist/feature/places/selectors"
import { dismissedCountryInfoAtom } from "./atoms"

type CountriesResponse = {
  boundsCountries: string[]
  searchCountry: null | string
}

export const onCountriesResponseSelector = selector<CountriesResponse | null>({
  key: "onCountriesResponseSelector",
  get: async ({ get }) => {
    const currentBounds = get(actionBoundsAtom)
    const selectedPlace = get(selectedGooglePlaceSelector)

    const url = new URL(`${window.location.origin}/api/v2/countries`)

    if (!currentBounds) return null

    url.searchParams.append("ne[]", currentBounds.north.toFixed(6))
    url.searchParams.append("ne[]", currentBounds.east.toFixed(6))

    url.searchParams.append("sw[]", currentBounds.south.toFixed(6))
    url.searchParams.append("sw[]", currentBounds.west.toFixed(6))

    if (selectedPlace) {
      url.searchParams.set("lat", selectedPlace.lat.toFixed(6))
      url.searchParams.set("lng", selectedPlace.lng.toFixed(6))
    }

    const result = (await (await fetch(url)).json()) as CountriesResponse

    return result
  },
})

export const onScreenCountriesSelector = selector<string[]>({
  key: "onScreenCountriesSelector",
  get: async ({ get }) => {
    const result = get(onCountriesResponseSelector)
    if (!result) return []
    return result.boundsCountries
  },
})

export const countryModalSelector = selector<string | null>({
  key: "countryModalSelector",
  get: async ({ get }) => {
    const result = get(onCountriesResponseSelector)
    if (!result || result.searchCountry === null) return null
    const { searchCountry } = result
    const dismissedCountryCodes = get(dismissedCountryInfoAtom)

    if (dismissedCountryCodes.includes(searchCountry)) return null
    return searchCountry
  },
})
