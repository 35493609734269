import {
  useControlPagination,
  useRequestNextPage,
  useRestoreScroll,
  useSingleFrameToggle,
} from "./hooks"
import React, { ReactElement, Suspense, useState } from "react"
import { ScrollWithHeaderFooter } from "@geome/react-components-next/lib/components/scrollWithHeaderFooter"
import { LocationsList } from "@geome/react-components-next/lib/components/locationsList"
import { LocationsListItem } from "./item"
import { BUPALocation } from "../../recoil/BUPATypes"
import { usePagination } from "@geome/recoil/dist/feature/resources/hooks"
import { TermsAndConditions } from "../termsAndConditions"
import { LoadingIndicator } from "../loadingIndicator"

export const InfiniteScrollPagedList = ({ showList }: { showList: boolean }): ReactElement => {
  const { moveToNextPage, nextPage, startPaginating, stopPaginating } = usePagination(
    "locations",
    "nearest_to"
  )
  const [isScrolledToBottom, setIsScolledToBottom] = useState(false)
  const [scrollReset, resetScroll] = useSingleFrameToggle()
  const { shouldRestore, setShouldRestore, restoredScrollPosition, setCurrentScrollPosition } =
    useRestoreScroll()

  useControlPagination({ setShouldRestore, startPaginating, stopPaginating, resetScroll })
  const { isLoading } = useRequestNextPage({ nextPage, moveToNextPage, isScrolledToBottom })
  const resetScrollPosition = scrollReset ? 0 : undefined

  return (
    <ScrollWithHeaderFooter
      className="app__sidebar-content"
      scrollPosition={restoredScrollPosition || resetScrollPosition}
      onScroll={({ scrollTop, remaining, user }) => {
        setIsScolledToBottom(scrollTop > 0 && remaining < 100)
        if (!user) return
        if (shouldRestore) setShouldRestore(false)
        setCurrentScrollPosition(`${scrollTop}`)
      }}
    >
      <>
        <Suspense>
          <TermsAndConditions />
        </Suspense>
        {showList && (
          <>
            <LocationsList endpoint="nearest_to" resource="locations">
              {(location: BUPALocation) => (
                <LocationsListItem key={location.id} location={location} />
              )}
            </LocationsList>
            {isLoading && <LoadingIndicator />}
          </>
        )}
      </>
    </ScrollWithHeaderFooter>
  )
}
